<template>
  <div class="scanPreResult" v-loading="loading">
    <el-row style="margin-top: 10px; margin-bottom: 10px;">
      <div class="search_container searchArea">
        <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
<!--          <el-form-item label="班级"  prop="hardId" v-if="userInfo.role=='teacher'">-->
<!--            <el-select v-model="searchForm.hardId" filterable placeholder="请选择" @change="hardChange">-->
<!--              <el-option key="" label="全部" value=""></el-option>-->
<!--              <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="学年学期" >
            <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
              <el-option key="" label="全部" value=""></el-option>
              <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属单位"  prop="schoolId" :label-width="formLabelWidth">
            <el-select v-model="searchForm.schoolId" filterable placeholder="请选择">
              <el-option key="" label="无" value=""></el-option>
              <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="查找：" prop="studentName" >
<!--            <el-input v-model="searchForm.studentName" placeholder="姓名"  @keyup.enter.native='searchRightList'></el-input>-->
            <el-input v-model="searchForm.searchText" placeholder="姓名或学号"  @input='searchForm.searchText' @keyup.enter.native="loadStudentList"></el-input>

          </el-form-item>

<!--          <el-form-item label="学号：" prop="studentNo" >-->
<!--            <el-input v-model="searchForm.studentNo" placeholder="学号"  @keyup.enter.native='searchRightList'></el-input>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="查找">-->
<!--            <el-input v-model="searchForm.searchText" placeholder="学号、姓名"  @keyup.enter.native='searchRightList'></el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type="info" size ="mini" icon="search" @click='btnSearch'>查询</el-button>
          </el-form-item>
          <el-form-item v-if="rightList && rightList.length > 0">
            人数：{{searchForm.totalCount}}
          </el-form-item>
        </el-form>
      </div>
    </el-row>
    <el-dialog title="修改密码" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">

      <rightPage v-if="selRight!=null" ref="rightPage" :info="selRight" @ok="examChange" @changeExam="examChange"></rightPage>

<!--      <el-form :model="changePassForm" ref="changePassDialogForm" :rules="changePassRules">-->
<!--        <el-form-item label="旧密码"  prop="pass" :label-width="formLabelWidth">-->
<!--          <el-input v-model="changePassForm.pass" type="password" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="新密码"  prop="newPass" :label-width="formLabelWidth">-->
<!--          <el-input v-model="changePassForm.newPass" type="password" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="确认新密码"  prop="pass" :label-width="formLabelWidth">-->
<!--          <el-input v-model="changePassForm.passConfirm" type="password" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->

<!--      </el-form>-->

<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="cancel">取 消</el-button>-->
<!--        <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>-->
<!--      </div>-->
    </el-dialog>

<!--      <el-dialog :title="myTitle" width="900px"  :visible.sync="dialogVisible" :close-on-click-modal="false">-->
        <el-table v-loading="loading" :data="tableData"  style="width: 100%"  align='center'>
          <el-table-column type="index" width="70" label="序号"></el-table-column>
          <el-table-column prop="student_name" label="姓名" align='center' ></el-table-column>
          <el-table-column prop="student_no" label="学号"   align='center' ></el-table-column>
          <el-table-column prop="exam_id" label="考试号"   align='center' ></el-table-column>
          <el-table-column prop="exam_name" label="考试名称" align='center'  ></el-table-column>
          <el-table-column prop="nick_name" label="创建老师" align='center'  ></el-table-column>
          <el-table-column prop="school_name" label="二级单位" align='center'  ></el-table-column>
<!--          <el-table-column prop="min_score" label="总分" align='center'  >-->
<!--            <template slot-scope='scope'>-->
<!--              {{-->
<!--                (scope.row.student_score ? scope.row.student_score : 0) +-->
<!--                (scope.row.zg_score ? scope.row.zg_score : 0)-->
<!--              }}-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="avg_score" label="考试影像查看" align='center'   >
            <template slot-scope='scope'>
              <el-button type="primary" size="mini" @click='selectStudent(scope.row)'>查看</el-button>
            </template>
          </el-table-column>

        </el-table>
    <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
</div>
</template>

<script>
import * as mUtils from '@/utils/mUtils'
import {mapState, mapActions} from 'vuex'
import XEUtils from 'xe-utils'
// import schoolDialog from "./schoolDialog";
import * as simpleApi from "request/simple";
import rightPage from "@/views/scanPreResult/rightPage.vue";
import Pagination from "components/pagination/index.vue";

export default {
  name: 'schoolList',
  data(){
    return {
      tableData: [], tableHeight:0,
      dialogVisible:false,
      acadyearTermList:[],
      schoolList:[],
      loading:false, isShow:false,
      selSchool: null, selSchoolDtl: null,
      searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, studentNo:null, studentName:null, searchText: null},
    }
  },
  components:{
    Pagination,
    rightPage
    // schoolDialog, Pagination
  },
  computed:{
    ...mapState({
      userInfo: state => state.user.userInfo,
    })
  },
  mounted() {
    this.loadAcadyearTermList();
    // this.loadExamHardList();
    this.loadSchoolList();
  },
  methods: {
    loadAcadyearTermList(){
      this.acadyearTermList = [];
      let now = new Date();
      let year = parseInt(now.getFullYear());
      for(let i=(year-3); i<(year+2); i++){
        this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
        this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
      }
    },
    selectStudent(row){
      this.dialogVisible = true;
      this.selRight = row;
    },
    btnSearch(){
      this.loadExamHardList();
    },
    loadSchoolList(){

      let search = { licenceId: this.userInfo.licenceId};
      let param = { controllerName: 'school', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
      return simpleApi.list(param).then(({result: {code, data}}) =>  {
        console.log(data)
        if (data.code == 0) {
          this.schoolList = data.result;
        }
      }).catch((error) => {
        console.log("error")
      });
    },
    loadExamHardList(){
      let search = {
                     licenceId: this.userInfo.licenceId,
                      searchText: mUtils.searchText(this.searchForm.searchText),
                      semester: mUtils.searchText(this.searchForm.semester),
                      schoolId:  mUtils.searchText(this.searchForm.schoolId),
                     orderBy: null};
      let param = { controllerName: 'analyse', methodName: '/history',  param: search};
      this.loading = true;
      return simpleApi.post(param).then(({result: {code, data}}) =>  {
        console.log("loadRightList", data)
        if (code == 0) {
          this.tableData = data.result;
          this.searchForm.pageNo = data.pageNo;
          this.searchForm.pageSize = data.pageSize;
          this.searchForm.totalCount = data.totalCount;
          console.log(this.tableData)
          console.log(this.searchForm)
          // this.examHardClassList = data;
          this.loading = false;
        }
      }).catch((error) => {
        console.log("error", error)
        this.loading = false;
      });
    },



    formatterStatus(item) {

      if(item.status == '00'){
        return '正常';
      }else if(item.status == '01'){
        return '禁用';
      }
      return '';

    },
    formatterAdminCount(row, column, cellValue, index){
      if(cellValue != null && cellValue > 0){
        return cellValue;
      }
      return '未设置';
    },
    // 上下分页
    handleCurrentChange(val){
      this.searchForm.pageNo = val;
      this.loadSchoolList()
    },
    // 每页显示多少条
    handleSizeChange(val){
      this.searchForm.pageSize = val;
      this.loadSchoolList()
    },



  },
}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

.search_container{

}
.btnRight{
  float: right;
  margin-right: 0px !important;
}
.searchArea{
  background:rgba(255,255,255,1);
  border-radius:2px;
  padding: 18px 18px 0;
}
.table_container{
  padding: 10px;
  background: #fff;
  border-radius: 2px;
}
.el-dialog--small{
  width: 600px !important;
}
.pagination{
  text-align: left;
  margin-top: 10px;
}

</style>


